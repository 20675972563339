import React from 'react'
import {Box, Heading, Text} from '@primer/components'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import StudentCardImage from '../assets/student-card.jpg'

export default function StudentCard() {
  return (
    <Layout isHeroPage={false}>
      <Box bg="blue.1" color="black" px={4} py={12}>
        <Box className="container-xl" px={2}>
          <Heading fontSize={6} textAlign="center" lineHeight={1}>
            Student ID Card
          </Heading>
          <Text as="p" textAlign="center" mb={8}>
            Please, do not disclose contents of the page unnecessarily.
          </Text>
          <Box px={5} as={Box} width={1} mx="auto">
            <picture>
              <img src={StudentCardImage} width="100%" height={null} alt="Student Card" />
            </picture>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Layout>
  )
}
